/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 0.625rem;
  line-height: 1.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

.no-scroll {
  height: 100vh;
  overflow: hidden;

  @supports (-webkit-touch-callout: none) {
    height: fill-available;

    body {
      min-height: fill-available;
    }
  }
}

body {
  font-size: 1.8rem;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
