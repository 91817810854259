/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  font-weight: 500;
  text-decoration: underline;

  &:hover {
    color: $color-hover;

    strong {
      color: $color-bright-sun;
    }
  }
}
