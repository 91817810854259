/* ==========================================================================
   #HERO
   ========================================================================== */

.c-hero__inner {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;

  @include bp('large') {
    flex-direction: row;
  }
}

.c-hero__content {
  margin-top: 2rem;

  @include bp('medium') {
    margin-top: 4rem;
  }

  @include bp('large') {
    margin-top: 0;
    max-width: 44%;
  }
}

.c-hero__title {
  font-size: 3.4rem;
  line-height: 1.25;
}

.c-hero__buttons {
  display: flex;
}

.c-hero__button {
  & + & {
    margin-left: 1.6rem;

    @include bp('large') {
      margin-left: 3.7rem;
    }
  }
}

.c-hero__media {
  width: 100%;

  @include bp('large') {
    max-width: 55%;
  }
}

.c-hero__media-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 61.5%;
  overflow: hidden;
  background-color: $color-mercury;
}

.c-hero__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.c-hero__video-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.c-hero__video {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
