/* ==========================================================================
   #CONTENT
   ========================================================================== */

.o-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pre,
  ul {
    margin: 0;
  }

  h1,
  h2 {
    font-weight: 600;
  }

  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  h1 {
    font-size: 2.4rem;

    @include bp('large') {
      font-size: 3.2rem;
    }
  }

  h2,
  h3 {
    font-size: 2.2rem;
    margin: 2rem 0;

    @include bp('large') {
      font-size: 2.4rem;
      margin: 2rem 0 0.4rem;
    }
  }

  h4,
  h5,
  h6 {
    font-size: 1.8rem;
    margin-bottom: 2rem;

    @include bp('large') {
      margin-bottom: 4rem;
    }
  }

  strong {
    font-weight: 700;
  }

  p {
    margin-bottom: 2.8rem;
  }

  code {
    font-weight: inherit;
    overflow-wrap: break-word;
    font-size: 0.889em;
    background-color: $color-very-light-grey;
    padding: 4px 7px;
  }

  ul,
  ol {
    margin-top: 2.8rem;
    margin-left: 0;
    list-style: none;
  }

  ul {
    margin-left: 0.2em;
  }

  li {
    position: relative;
    margin-bottom: 2rem;

    @include bp('large') {
      margin-bottom: 2.8rem;
    }
  }

  ol {
    counter-reset: list;
  }

  ol > li {
    list-style: decimal;

    &::marker {
      content: none;
    }

    &::before {
      counter-increment: list;
      content: counter(list) '.';
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  ul > li {
    &::before {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      top: 8px;
      left: 0;
      background-color: $color-secondary;
    }
  }

  ol > li,
  ul > li {
    padding-left: 3.3rem;
  }

  li:not(:last-child) {
    margin-bottom: 2rem;

    @include bp('large') {
      margin-bottom: 2.6rem;
    }
  }

  a {
    font-weight: 500;
    overflow-wrap: break-word;
  }

  pre > code[class*='language-'] {
    overflow-wrap: break-word;
    margin-bottom: 3rem;

    @include bp('large') {
      margin-bottom: 4.8rem;
    }
  }

  blockquote p {
    position: relative;
    font-style: normal;
    color: $color-cornflower-blue;
    padding-left: 29px;

    &::before {
      @extend %o-icons;
      @extend %o-icons--checkmark;

      position: absolute;
      left: 0;
      top: 6px;
    }

    &::after {
      content: none;
    }
  }

  figure {
    position: relative;
    padding-bottom: 56%;
    margin-bottom: 4rem;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border: none;
    }
  }

  .c-intro {
    font-weight: 600;
    margin: 2.4rem 0 0.8rem;
    font-size: 2rem;

    @include bp('large') {
      font-size: 2.4rem;
      margin: 3.6rem 0 1.2rem;
    }

    a {
      font-weight: inherit;
    }
  }

  .c-post__entry {
    > :first-child:not(p) {
      margin-top: 2rem;

      @include bp('large') {
        margin-top: 4.2rem;
      }
    }
  }

  .c-page-nav {
    margin-top: 5.8rem;
    display: flex;
    justify-content: space-between;
  }
}
