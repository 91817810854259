/* stylelint-disable property-no-vendor-prefix, selector-no-vendor-prefix, selector-no-qualifying-type, no-descending-specificity, selector-class-pattern */

code[class*='language-'],
pre[class*='language-'] {
  color: $color-titan-white;
  background: $color-rhino;
  border-radius: 1rem;
  font-family: 'Lucida Console', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono',
    monospace;
  font-size: 1.4rem;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;

  &.language-text-md {
    white-space: pre-line;
  }
}

pre[class*='language-']::selection,
pre[class*='language-'] ::selection,
code[class*='language-']::selection,
code[class*='language-'] ::selection {
  background: $color-primary;
}

@media print {
  code[class*='language-'],
  pre[class*='language-'] {
    text-shadow: none;
  }
}

/* Code blocks */
pre > code[class*='language-'] {
  padding: 3rem 2rem;
  margin: 0 0 2.5rem 0;
  overflow: auto;
  display: block;
  background: #2d2b57;
  color: #e3dfff;
  font-weight: normal;
  font-size: 1.4rem;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

/**
 * Shades of Purple Theme — for Highlightjs.
 *
 * @author (c) Ahmad Awais <https://twitter.com/mrahmadawais/>
 * @link GitHub Repo → https://github.com/ahmadawais/Shades-of-Purple-HighlightJS
 * @version 1.5.0
 */

.hljs-subst {
  color: #e3dfff;
}

.hljs-title {
  color: #fad000;
  font-weight: normal;
}

.hljs-name {
  color: #a1feff;
}

.hljs-tag {
  color: #fff;
}

.hljs-attr {
  color: #f8d000;
  font-style: italic;
}

.hljs-built_in,
.hljs-selector-tag,
.hljs-section {
  color: #fb9e00;
}

.hljs-keyword {
  color: #fb9e00;
}

.hljs-string,
.hljs-attribute,
.hljs-symbol,
.hljs-bullet,
.hljs-addition,
.hljs-code,
.hljs-regexp,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-template-tag,
.hljs-quote,
.hljs-deletion {
  color: #4cd213;
}

.hljs-meta,
.hljs-meta-string {
  color: #fb9e00;
}

.hljs-comment {
  color: #ac65ff;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-name,
.hljs-strong {
  font-weight: normal;
}

.hljs-literal,
.hljs-number {
  color: #fa658d;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
