@keyframes o-pulse-circle {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-primary, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 1.5rem rgba($color-primary, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($color-primary, 0);
  }
}
