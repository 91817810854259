/* ==========================================================================
   #POST
   ========================================================================== */

.c-post {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @include bp('large') {
    flex-direction: row;
  }
}

.c-post__article {
  display: flex;
  flex-direction: column;

  @include bp('large') {
    max-width: 58%;
  }
}
