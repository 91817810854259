@charset "UTF-8";
/*
  Project: Getchisel
  Author: Xfive
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Spacing
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* quicksand-300 - latin */
/* quicksand-regular - latin */
@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/quicksand-v21-latin-regular.woff2") format("woff2");
  /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* quicksand-500 - latin */
@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../assets/fonts/quicksand-v21-latin-500.woff2") format("woff2");
  /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* quicksand-600 - latin */
/* quicksand-700 - latin */
@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/quicksand-v21-latin-700.woff2") format("woff2");
  /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}
blockquote p:first-child::before {
  content: open-quote;
}
blockquote p:last-child::after {
  content: close-quote;
}

:not(pre) > code {
  font-family: "Lucida Console", Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-weight: 400;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
html {
  background: #fff;
  color: #2a1468;
  font-family: "Quicksand", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-size: 0.625rem;
  line-height: 1.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

.no-scroll {
  height: 100vh;
  overflow: hidden;
}
@supports (-webkit-touch-callout: none) {
  .no-scroll {
    height: fill-available;
  }
  .no-scroll body {
    min-height: fill-available;
  }
}

body {
  font-size: 1.8rem;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #2a1468;
  font-weight: 500;
  text-decoration: underline;
}
a:hover {
  color: #ff6d54;
}
a:hover strong {
  color: #fed049;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* stylelint-disable property-no-vendor-prefix, selector-no-vendor-prefix, selector-no-qualifying-type, no-descending-specificity, selector-class-pattern */
code[class*=language-],
pre[class*=language-] {
  color: #e3dfff;
  background: #2d2b57;
  border-radius: 1rem;
  font-family: "Lucida Console", Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1.4rem;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
code[class*=language-].language-text-md,
pre[class*=language-].language-text-md {
  white-space: pre-line;
}

pre[class*=language-]::selection,
pre[class*=language-] ::selection,
code[class*=language-]::selection,
code[class*=language-] ::selection {
  background: #2a1468;
}

@media print {
  code[class*=language-],
pre[class*=language-] {
    text-shadow: none;
  }
}
/* Code blocks */
pre > code[class*=language-] {
  padding: 3rem 2rem;
  margin: 0 0 2.5rem 0;
  overflow: auto;
  display: block;
  background: #2d2b57;
  color: #e3dfff;
  font-weight: normal;
  font-size: 1.4rem;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

/**
 * Shades of Purple Theme — for Highlightjs.
 *
 * @author (c) Ahmad Awais <https://twitter.com/mrahmadawais/>
 * @link GitHub Repo → https://github.com/ahmadawais/Shades-of-Purple-HighlightJS
 * @version 1.5.0
 */
.hljs-subst {
  color: #e3dfff;
}

.hljs-title {
  color: #fad000;
  font-weight: normal;
}

.hljs-name {
  color: #a1feff;
}

.hljs-tag {
  color: #fff;
}

.hljs-attr {
  color: #f8d000;
  font-style: italic;
}

.hljs-built_in,
.hljs-selector-tag,
.hljs-section {
  color: #fb9e00;
}

.hljs-keyword {
  color: #fb9e00;
}

.hljs-string,
.hljs-attribute,
.hljs-symbol,
.hljs-bullet,
.hljs-addition,
.hljs-code,
.hljs-regexp,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-template-tag,
.hljs-quote,
.hljs-deletion {
  color: #4cd213;
}

.hljs-meta,
.hljs-meta-string {
  color: #fb9e00;
}

.hljs-comment {
  color: #ac65ff;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-name,
.hljs-strong {
  font-weight: normal;
}

.hljs-literal,
.hljs-number {
  color: #fa658d;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

@keyframes o-pulse-circle {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(42, 20, 104, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 1.5rem rgba(42, 20, 104, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(42, 20, 104, 0);
  }
}
/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 122rem;
  padding: 0 2rem;
}

/* ==========================================================================
   #CONTENT
   ========================================================================== */
.o-content h1,
.o-content h2,
.o-content h3,
.o-content h4,
.o-content h5,
.o-content h6,
.o-content p,
.o-content pre,
.o-content ul {
  margin: 0;
}
.o-content h1,
.o-content h2 {
  font-weight: 600;
}
.o-content h3,
.o-content h4,
.o-content h5,
.o-content h6 {
  font-weight: 500;
}
.o-content h1 {
  font-size: 2.4rem;
}
@media (min-width: 64rem) {
  .o-content h1 {
    font-size: 3.2rem;
  }
}
.o-content h2,
.o-content h3 {
  font-size: 2.2rem;
  margin: 2rem 0;
}
@media (min-width: 64rem) {
  .o-content h2,
.o-content h3 {
    font-size: 2.4rem;
    margin: 2rem 0 0.4rem;
  }
}
.o-content h4,
.o-content h5,
.o-content h6 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
@media (min-width: 64rem) {
  .o-content h4,
.o-content h5,
.o-content h6 {
    margin-bottom: 4rem;
  }
}
.o-content strong {
  font-weight: 700;
}
.o-content p {
  margin-bottom: 2.8rem;
}
.o-content code {
  font-weight: inherit;
  overflow-wrap: break-word;
  font-size: 0.889em;
  background-color: #f0f1f3;
  padding: 4px 7px;
}
.o-content ul,
.o-content ol {
  margin-top: 2.8rem;
  margin-left: 0;
  list-style: none;
}
.o-content ul {
  margin-left: 0.2em;
}
.o-content li {
  position: relative;
  margin-bottom: 2rem;
}
@media (min-width: 64rem) {
  .o-content li {
    margin-bottom: 2.8rem;
  }
}
.o-content ol {
  counter-reset: list;
}
.o-content ol > li {
  list-style: decimal;
}
.o-content ol > li::marker {
  content: none;
}
.o-content ol > li::before {
  counter-increment: list;
  content: counter(list) ".";
  position: absolute;
  top: 0;
  left: 0;
}
.o-content ul > li::before {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  top: 8px;
  left: 0;
  background-color: #ff6d54;
}
.o-content ol > li,
.o-content ul > li {
  padding-left: 3.3rem;
}
.o-content li:not(:last-child) {
  margin-bottom: 2rem;
}
@media (min-width: 64rem) {
  .o-content li:not(:last-child) {
    margin-bottom: 2.6rem;
  }
}
.o-content a {
  font-weight: 500;
  overflow-wrap: break-word;
}
.o-content pre > code[class*=language-] {
  overflow-wrap: break-word;
  margin-bottom: 3rem;
}
@media (min-width: 64rem) {
  .o-content pre > code[class*=language-] {
    margin-bottom: 4.8rem;
  }
}
.o-content blockquote p {
  position: relative;
  font-style: normal;
  color: #915ff2;
  padding-left: 29px;
}
.o-content blockquote p::before {
  position: absolute;
  left: 0;
  top: 6px;
}
.o-content blockquote p::after {
  content: none;
}
.o-content figure {
  position: relative;
  padding-bottom: 56%;
  margin-bottom: 4rem;
}
.o-content figure iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: none;
}
.o-content .c-intro {
  font-weight: 600;
  margin: 2.4rem 0 0.8rem;
  font-size: 2rem;
}
@media (min-width: 64rem) {
  .o-content .c-intro {
    font-size: 2.4rem;
    margin: 3.6rem 0 1.2rem;
  }
}
.o-content .c-intro a {
  font-weight: inherit;
}
.o-content .c-post__entry > :first-child:not(p) {
  margin-top: 2rem;
}
@media (min-width: 64rem) {
  .o-content .c-post__entry > :first-child:not(p) {
    margin-top: 4.2rem;
  }
}
.o-content .c-page-nav {
  margin-top: 5.8rem;
  display: flex;
  justify-content: space-between;
}

/* ==========================================================================
   #ICONS
   ========================================================================== */
.o-icons::before,
.o-content blockquote p::before {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

.o-icons--star::before {
  width: 33px;
  height: 33px;
  background-image: url("~assets/images/star.svg");
}

.o-icons--play::before {
  background-image: url("~assets/images/play.svg");
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.o-icons--arrow::before {
  background-image: url("~assets/images/arrow.svg");
  width: 28px;
  height: 17px;
  border-radius: 100%;
}

.o-content blockquote p::before {
  background-image: url("~assets/images/checkmark.svg");
  width: 17px;
  height: 17px;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 48rem) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}
.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 48rem) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 48rem) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 48rem) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #BLOCK
   ========================================================================== */
.c-block {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
@media (min-width: 48rem) {
  .c-block {
    flex-direction: row;
    align-items: flex-end;
  }
}
@media (min-width: 64rem) {
  .c-block {
    justify-content: space-between;
  }
}

@media (min-width: 48rem) {
  .c-block--reversed {
    flex-direction: row-reverse;
  }
}

.c-block__content {
  margin-top: 4rem;
}
@media (min-width: 48rem) {
  .c-block__content {
    width: 50%;
    margin-right: 6rem;
    margin-top: 0;
  }
}
@media (min-width: 64rem) {
  .c-block__content {
    width: 40%;
  }
}
@media (min-width: 48rem) {
  .c-block--reversed .c-block__content {
    margin-right: 0;
    margin-left: 6rem;
  }
}
@media (min-width: 64rem) {
  .c-block--reversed .c-block__content {
    width: 40%;
  }
}
.c-block__content::after {
  content: "";
  display: block;
  width: 31px;
  height: 20px;
  background-color: #fed049;
  margin-top: 3rem;
}

.c-block__image-wrapper {
  flex-shrink: 0;
}
@media (min-width: 48rem) {
  .c-block__image-wrapper {
    width: 50%;
  }
}
@media (min-width: 64rem) {
  .c-block__image-wrapper {
    width: 33%;
  }
}

.c-block__image {
  width: 100%;
  object-fit: contain;
}
@media (min-width: 64rem) {
  .c-block__image {
    width: auto;
  }
}

.c-block__title {
  font-size: 2.4rem;
}

.c-block__post-entry {
  font-size: 1.8rem;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  text-decoration: none;
  /* [4] */
  cursor: pointer;
  /* [5] */
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  line-height: 1.375;
  font-size: 1.6rem;
  padding: 1.4rem;
}
@media (min-width: 30rem) {
  .c-btn {
    padding: 1.4rem 3.1rem;
  }
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #ff6d54;
}
.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  color: #fff;
}
.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #2a1468;
}

.c-btn--secondary {
  background-color: #fff;
  border: 1px solid #ff6d54;
}
.c-btn--secondary, .c-btn--secondary:hover, .c-btn--secondary:active, .c-btn--secondary:focus {
  color: #2a1468;
}
.c-btn--secondary:hover, .c-btn--secondary:focus {
  background-color: #2a1468;
  border-color: #2a1468;
  color: #fff;
}

.c-btn--video {
  background-color: transparent;
  padding: 0;
  height: 100%;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-btn--video.loading::before {
  animation: o-pulse-circle 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.c-menu-button {
  position: absolute;
  top: 10.6rem;
  left: calc(100% - 1px);
  height: 26px;
  width: 29px;
  padding: 2rem;
  color: #2a1468;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid;
  pointer-events: all !important;
}
@media (min-width: 64rem) {
  .c-menu-button {
    display: none;
  }
}
@media (min-width: 30rem) {
  .c-menu-button {
    top: 8.6rem;
  }
}
.c-sidebar--active .c-menu-button {
  left: auto;
  right: 2rem;
}

.c-menu-button-bar {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  width: 30px;
  height: 4px;
  border-radius: 4px;
  background-color: #2a1468;
  transition: all 0.3s ease;
  transform: translateX(-50%);
  left: 50%;
}
.c-menu-button-bar.c-menu-button-bar--top {
  transform: translate(-50%, -8px);
}
.c-menu-button-bar.c-menu-button-bar--bottom {
  transform: translate(-50%, 8px);
}
.c-sidebar--active .c-menu-button-bar {
  background-color: #ff6d54;
}
.c-sidebar--active .c-menu-button-bar.c-menu-button-bar--top {
  transform: translate(-50%, 0) rotate(45deg);
}
.c-sidebar--active .c-menu-button-bar.c-menu-button-bar--middle {
  opacity: 0;
}
.c-sidebar--active .c-menu-button-bar.c-menu-button-bar--bottom {
  transform: translate(-50%, 0) rotate(-45deg);
}
.c-menu-button:hover .c-menu-button-bar {
  background-color: #ff6d54;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  font-size: 1.8rem;
  background-color: #f0f1f3;
  font-weight: 500;
}

.c-footer__container {
  padding-top: 5.4rem;
  padding-bottom: 4.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 29.98rem) {
  .c-footer__container {
    flex-direction: column;
  }
}

.c-footer__link {
  text-decoration: none;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  background-color: #f0f1f3;
}

.c-header__inner {
  padding: 2rem 0;
  min-height: 11rem;
  display: flex;
  justify-content: space-between;
}

.c-header__left {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 64rem) {
  .c-header__left {
    justify-content: initial;
    flex-direction: row;
    width: auto;
  }
}

.c-header__logo {
  padding-bottom: 1rem;
  font-size: 0.7em;
}
@media (min-width: 64rem) {
  .c-header__logo {
    padding-bottom: 0;
    padding-right: 8rem;
    font-size: 1em;
  }
}

.c-header__nav {
  display: flex;
  list-style: none;
  margin: 1rem -1.5rem;
}
@media (max-width: 47.98rem) {
  .c-header__nav {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (min-width: 64rem) {
  .c-header__nav {
    margin: 0 -2.5rem;
  }
}

.c-header__nav-item {
  margin: 0.8rem;
}
@media (min-width: 30rem) {
  .c-header__nav-item {
    margin: 0;
  }
}
@media (min-width: 64rem) {
  .c-header__nav-item {
    padding: 0 1.5rem;
  }
}
.c-header__nav-item--current a {
  color: #ff6d54;
}

.c-header__nav-link {
  text-decoration: none;
  font-weight: 500;
  padding: 1rem;
}

.c-header__right {
  display: flex;
  align-items: center;
}
@media (max-width: 63.98rem) {
  .c-header__right {
    display: none;
  }
}

.c-header__right-iframe {
  height: 30px;
  width: 130px;
  border: 0;
}

/* ==========================================================================
   #HERO
   ========================================================================== */
.c-hero__inner {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}
@media (min-width: 64rem) {
  .c-hero__inner {
    flex-direction: row;
  }
}

.c-hero__content {
  margin-top: 2rem;
}
@media (min-width: 48rem) {
  .c-hero__content {
    margin-top: 4rem;
  }
}
@media (min-width: 64rem) {
  .c-hero__content {
    margin-top: 0;
    max-width: 44%;
  }
}

.c-hero__title {
  font-size: 3.4rem;
  line-height: 1.25;
}

.c-hero__buttons {
  display: flex;
}

.c-hero__button + .c-hero__button {
  margin-left: 1.6rem;
}
@media (min-width: 64rem) {
  .c-hero__button + .c-hero__button {
    margin-left: 3.7rem;
  }
}

.c-hero__media {
  width: 100%;
}
@media (min-width: 64rem) {
  .c-hero__media {
    max-width: 55%;
  }
}

.c-hero__media-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 61.5%;
  overflow: hidden;
  background-color: #e4e4e4;
}

.c-hero__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.c-hero__video-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.c-hero__video {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* ==========================================================================
   #HIGHLIGHT
   ========================================================================== */
.c-highlight {
  color: #ff6d54;
  font-weight: inherit;
}

/* ==========================================================================
   #LOGO
   ========================================================================== */
.c-logo__link {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.c-logo__link > * {
  text-decoration: none;
}

.c-logo__icon-wrapper {
  padding-right: 0.4905555556em;
}

.c-logo__icon {
  display: block;
  width: 1em;
  height: 1em;
  font-size: 2.4533333333em;
}

.c-logo__text {
  font-size: 1.8333333333em;
  font-weight: 700;
  color: #ff6d54;
  text-decoration: none;
}

/* ==========================================================================
   #PAGE-NAV
   ========================================================================== */
.c-page-nav {
  display: flex;
}

.c-page-nav__item--prev {
  padding-left: 5.2rem;
}
.c-page-nav__item--next {
  padding: 0 5.2rem 0 2rem;
  margin-left: auto;
  text-align: right;
}

.c-page-nav__link {
  text-decoration: none;
}

.c-page-nav__heading {
  display: block;
}

.c-page-nav__text {
  position: relative;
  display: block;
  font-weight: 600;
  font-size: 1.8rem;
  margin-top: 0.5rem;
}
@media (min-width: 48rem) {
  .c-page-nav__text {
    font-size: 2.4rem;
  }
}
.c-page-nav__text::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 28px;
  height: 17px;
}
.c-page-nav__item--prev .c-page-nav__text::before {
  left: -5.2rem;
}
.c-page-nav__item--next .c-page-nav__text::before {
  transform: translateY(-50%) rotate(180deg);
  right: -5.2rem;
}

/* ==========================================================================
   #POST
   ========================================================================== */
.c-post {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}
@media (min-width: 64rem) {
  .c-post {
    flex-direction: row;
  }
}

.c-post__article {
  display: flex;
  flex-direction: column;
}
@media (min-width: 64rem) {
  .c-post__article {
    max-width: 58%;
  }
}

/* ==========================================================================
   #SIDEBAR
   ========================================================================== */
.c-sidebar {
  position: fixed;
  transform: translateX(-100%);
  top: 0;
  left: 0;
  flex-shrink: 0;
  margin-right: 5.5rem;
  padding: 9.5rem 2rem 0;
  height: 100%;
  width: 100%;
  transition: transform 0.2s;
  background-color: #fff;
  z-index: 100;
}
.c-sidebar--active {
  transform: translateX(0);
  overflow-y: auto;
}
@media (min-width: 64rem) {
  .c-sidebar {
    position: sticky;
    transform: none;
    height: max-content;
    width: 18.5%;
    padding: 9.5rem 0 0;
  }
}
.c-sidebar a {
  text-decoration: none;
}

.c-sidebar__titles {
  margin-top: 2.2rem;
  overflow-x: hidden;
  overflow-y: auto;
}
@media (min-width: 64rem) {
  .c-sidebar__titles {
    max-height: calc(100vh - 26rem);
  }
}
.c-sidebar__titles li {
  margin-bottom: 0.5rem;
}
@media (min-width: 64rem) {
  .c-sidebar__titles li {
    margin-bottom: 0;
  }
}

.c-sidebar__child--level-1 > .c-sidebar__link {
  font-weight: 600;
}
.c-sidebar__child--level-1:not(:last-child) {
  margin-bottom: 3rem;
}
.c-sidebar__child--level-1 .c-sidebar__children {
  display: none;
  margin-top: 0;
}
.c-sidebar__child--level-1.c-sidebar__active-parent .c-sidebar__children, .c-sidebar__child--level-1.c-sidebar__current .c-sidebar__children {
  display: block;
}

.c-sidebar__current {
  color: #ff6d54;
  font-weight: 500;
}
.c-sidebar__current > a {
  color: inherit;
}

.c-sidebar__title {
  text-transform: uppercase;
  font-weight: 500;
}

/* Sidebar variants
   ========================================================================== */
.c-page-sidebar {
  margin-right: 0;
  padding-right: 0;
  top: 0;
  right: 0;
  flex-shrink: 0;
  height: max-content;
  width: 100%;
  margin-left: 0;
  padding-top: 0;
  margin-top: 2rem;
  position: relative;
  font-size: 1.6rem;
}
@media (min-width: 64rem) {
  .c-page-sidebar {
    width: 11%;
    padding-top: 9.5rem;
    margin-top: 7.5rem;
    position: sticky;
  }
}

.c-page-sidebar__link {
  text-decoration: none;
}
.c-page-sidebar__link--active {
  font-weight: 600;
}
.c-page-sidebar__link--selected {
  color: #ff6d54;
}

.c-sidebar__headings {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 31rem);
}
.c-sidebar__headings li {
  margin-bottom: 0.75em;
}

/* ==========================================================================
   #SLIDER
   ========================================================================== */
.c-slider__container {
  text-align: center;
}

.c-slider__title {
  font-size: 3.4rem;
  line-height: 1.25;
  font-weight: 700;
}

.c-slider__slides {
  overflow: hidden;
  display: flex;
  margin: -2rem 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 64rem) {
  .c-slider__slides {
    flex-direction: row;
    margin: 0 -1rem;
  }
}

.c-slider__slide {
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.1));
  flex-shrink: 0;
  width: 100%;
  margin: 2rem 0;
  transition: transform 0.2s;
}
.c-slider__slide:hover {
  transform: scale(1.1);
}
@media (min-width: 48rem) {
  .c-slider__slide {
    width: 60%;
  }
}
@media (min-width: 64rem) {
  .c-slider__slide {
    height: 128px;
    margin: 0 1rem;
    width: 23%;
  }
}

.c-slider__slide-image {
  width: 100%;
  object-fit: contain;
  height: auto;
}

.c-slider__pagination-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-slider__pagination-item {
  width: 44px;
  height: 2px;
  margin: 0 0.25rem;
  background-color: #e4e4e4;
}

.c-slider__pagination-item--current {
  background-color: #494771;
}

/* ==========================================================================
   #WHY-CHISEL
   ========================================================================== */
.c-why-chisel__title {
  font-size: 3.4rem;
  text-align: center;
}

.c-why-chisel__feature-item + .c-why-chisel__feature-item {
  margin-top: 10rem;
}
@media (min-width: 48rem) {
  .c-why-chisel__feature-item + .c-why-chisel__feature-item {
    margin-top: 18rem;
  }
}

.c-why-chisel__cta {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-why-chisel__cta-text {
  font-weight: 500;
  margin: 0 8px;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 48rem) {
  .u-hidden\@small {
    display: block;
  }
}

/* stylelint-disable max-nesting-depth */
/* ==========================================================================
   #SPACING
   ========================================================================== */
.margin-auto {
  margin: auto;
}

.margin-top-auto {
  margin-top: auto;
}

.margin-right-auto {
  margin-right: auto;
}

.margin-bottom-auto {
  margin-bottom: auto;
}

.margin-left-auto {
  margin-left: auto;
}

@media (min-width: 30rem) {
  .margin-top-small-auto {
    margin-top: auto;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-auto {
    margin-right: auto;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-auto {
    margin-bottom: auto;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-auto {
    margin-left: auto;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-auto {
    margin-top: auto;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-auto {
    margin-right: auto;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-auto {
    margin-bottom: auto;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-auto {
    margin-left: auto;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-auto {
    margin-top: auto;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-auto {
    margin-right: auto;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-auto {
    margin-bottom: auto;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-auto {
    margin-left: auto;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-auto {
    margin-top: auto;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-auto {
    margin-right: auto;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-auto {
    margin-bottom: auto;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-auto {
    margin-left: auto;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-auto {
    margin-top: auto;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-auto {
    margin-right: auto;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-auto {
    margin-bottom: auto;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-auto {
    margin-left: auto;
  }
}

.margin-0 {
  margin: 0;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-left-0 {
  margin-left: 0;
}

@media (min-width: 30rem) {
  .margin-top-small-0 {
    margin-top: 0;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-0 {
    margin-right: 0;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-0 {
    margin-left: 0;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-0 {
    margin-top: 0;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-0 {
    margin-right: 0;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-0 {
    margin-left: 0;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-0 {
    margin-top: 0;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-0 {
    margin-right: 0;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-0 {
    margin-left: 0;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-0 {
    margin-top: 0;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-0 {
    margin-right: 0;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-0 {
    margin-left: 0;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-0 {
    margin-top: 0;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-0 {
    margin-right: 0;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-0 {
    margin-bottom: 0;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-0 {
    margin-left: 0;
  }
}

.margin-2 {
  margin: 2rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

@media (min-width: 30rem) {
  .margin-top-small-2 {
    margin-top: 2rem;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-2 {
    margin-right: 2rem;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-2 {
    margin-bottom: 2rem;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-2 {
    margin-left: 2rem;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-2 {
    margin-top: 2rem;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-2 {
    margin-right: 2rem;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-2 {
    margin-bottom: 2rem;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-2 {
    margin-left: 2rem;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-2 {
    margin-top: 2rem;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-2 {
    margin-right: 2rem;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-2 {
    margin-bottom: 2rem;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-2 {
    margin-left: 2rem;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-2 {
    margin-top: 2rem;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-2 {
    margin-right: 2rem;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-2 {
    margin-bottom: 2rem;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-2 {
    margin-left: 2rem;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-2 {
    margin-top: 2rem;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-2 {
    margin-right: 2rem;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-2 {
    margin-bottom: 2rem;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-2 {
    margin-left: 2rem;
  }
}

.margin-3 {
  margin: 3rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-right-3 {
  margin-right: 3rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.margin-left-3 {
  margin-left: 3rem;
}

@media (min-width: 30rem) {
  .margin-top-small-3 {
    margin-top: 3rem;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-3 {
    margin-right: 3rem;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-3 {
    margin-bottom: 3rem;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-3 {
    margin-left: 3rem;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-3 {
    margin-top: 3rem;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-3 {
    margin-right: 3rem;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-3 {
    margin-bottom: 3rem;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-3 {
    margin-left: 3rem;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-3 {
    margin-top: 3rem;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-3 {
    margin-right: 3rem;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-3 {
    margin-bottom: 3rem;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-3 {
    margin-left: 3rem;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-3 {
    margin-top: 3rem;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-3 {
    margin-right: 3rem;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-3 {
    margin-bottom: 3rem;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-3 {
    margin-left: 3rem;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-3 {
    margin-top: 3rem;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-3 {
    margin-right: 3rem;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-3 {
    margin-bottom: 3rem;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-3 {
    margin-left: 3rem;
  }
}

.margin-4 {
  margin: 4rem;
}

.margin-top-4 {
  margin-top: 4rem;
}

.margin-right-4 {
  margin-right: 4rem;
}

.margin-bottom-4 {
  margin-bottom: 4rem;
}

.margin-left-4 {
  margin-left: 4rem;
}

@media (min-width: 30rem) {
  .margin-top-small-4 {
    margin-top: 4rem;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-4 {
    margin-right: 4rem;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-4 {
    margin-bottom: 4rem;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-4 {
    margin-left: 4rem;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-4 {
    margin-top: 4rem;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-4 {
    margin-right: 4rem;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-4 {
    margin-bottom: 4rem;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-4 {
    margin-left: 4rem;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-4 {
    margin-top: 4rem;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-4 {
    margin-right: 4rem;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-4 {
    margin-bottom: 4rem;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-4 {
    margin-left: 4rem;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-4 {
    margin-top: 4rem;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-4 {
    margin-right: 4rem;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-4 {
    margin-bottom: 4rem;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-4 {
    margin-left: 4rem;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-4 {
    margin-top: 4rem;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-4 {
    margin-right: 4rem;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-4 {
    margin-bottom: 4rem;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-4 {
    margin-left: 4rem;
  }
}

.margin-6 {
  margin: 6rem;
}

.margin-top-6 {
  margin-top: 6rem;
}

.margin-right-6 {
  margin-right: 6rem;
}

.margin-bottom-6 {
  margin-bottom: 6rem;
}

.margin-left-6 {
  margin-left: 6rem;
}

@media (min-width: 30rem) {
  .margin-top-small-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-6 {
    margin-left: 6rem;
  }
}

.margin-8 {
  margin: 8rem;
}

.margin-top-8 {
  margin-top: 8rem;
}

.margin-right-8 {
  margin-right: 8rem;
}

.margin-bottom-8 {
  margin-bottom: 8rem;
}

.margin-left-8 {
  margin-left: 8rem;
}

@media (min-width: 30rem) {
  .margin-top-small-8 {
    margin-top: 8rem;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-8 {
    margin-right: 8rem;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-8 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-8 {
    margin-left: 8rem;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-8 {
    margin-top: 8rem;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-8 {
    margin-right: 8rem;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-8 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-8 {
    margin-left: 8rem;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-8 {
    margin-top: 8rem;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-8 {
    margin-right: 8rem;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-8 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-8 {
    margin-left: 8rem;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-8 {
    margin-top: 8rem;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-8 {
    margin-right: 8rem;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-8 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-8 {
    margin-left: 8rem;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-8 {
    margin-top: 8rem;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-8 {
    margin-right: 8rem;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-8 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-8 {
    margin-left: 8rem;
  }
}

.margin-10 {
  margin: 8rem;
}

.margin-top-10 {
  margin-top: 8rem;
}

.margin-right-10 {
  margin-right: 8rem;
}

.margin-bottom-10 {
  margin-bottom: 8rem;
}

.margin-left-10 {
  margin-left: 8rem;
}

@media (min-width: 30rem) {
  .margin-top-small-10 {
    margin-top: 8rem;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-10 {
    margin-right: 8rem;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-10 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-10 {
    margin-left: 8rem;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-10 {
    margin-top: 8rem;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-10 {
    margin-right: 8rem;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-10 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-10 {
    margin-left: 8rem;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-10 {
    margin-top: 8rem;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-10 {
    margin-right: 8rem;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-10 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-10 {
    margin-left: 8rem;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-10 {
    margin-top: 8rem;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-10 {
    margin-right: 8rem;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-10 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-10 {
    margin-left: 8rem;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-10 {
    margin-top: 8rem;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-10 {
    margin-right: 8rem;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-10 {
    margin-bottom: 8rem;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-10 {
    margin-left: 8rem;
  }
}

.margin-12 {
  margin: 12rem;
}

.margin-top-12 {
  margin-top: 12rem;
}

.margin-right-12 {
  margin-right: 12rem;
}

.margin-bottom-12 {
  margin-bottom: 12rem;
}

.margin-left-12 {
  margin-left: 12rem;
}

@media (min-width: 30rem) {
  .margin-top-small-12 {
    margin-top: 12rem;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-12 {
    margin-right: 12rem;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-12 {
    margin-bottom: 12rem;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-12 {
    margin-left: 12rem;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-12 {
    margin-top: 12rem;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-12 {
    margin-right: 12rem;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-12 {
    margin-bottom: 12rem;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-12 {
    margin-left: 12rem;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-12 {
    margin-top: 12rem;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-12 {
    margin-right: 12rem;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-12 {
    margin-bottom: 12rem;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-12 {
    margin-left: 12rem;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-12 {
    margin-top: 12rem;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-12 {
    margin-right: 12rem;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-12 {
    margin-bottom: 12rem;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-12 {
    margin-left: 12rem;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-12 {
    margin-top: 12rem;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-12 {
    margin-right: 12rem;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-12 {
    margin-bottom: 12rem;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-12 {
    margin-left: 12rem;
  }
}

.margin-14 {
  margin: 14rem;
}

.margin-top-14 {
  margin-top: 14rem;
}

.margin-right-14 {
  margin-right: 14rem;
}

.margin-bottom-14 {
  margin-bottom: 14rem;
}

.margin-left-14 {
  margin-left: 14rem;
}

@media (min-width: 30rem) {
  .margin-top-small-14 {
    margin-top: 14rem;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-14 {
    margin-right: 14rem;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-14 {
    margin-bottom: 14rem;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-14 {
    margin-left: 14rem;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-14 {
    margin-top: 14rem;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-14 {
    margin-right: 14rem;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-14 {
    margin-bottom: 14rem;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-14 {
    margin-left: 14rem;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-14 {
    margin-top: 14rem;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-14 {
    margin-right: 14rem;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-14 {
    margin-bottom: 14rem;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-14 {
    margin-left: 14rem;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-14 {
    margin-top: 14rem;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-14 {
    margin-right: 14rem;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-14 {
    margin-bottom: 14rem;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-14 {
    margin-left: 14rem;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-14 {
    margin-top: 14rem;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-14 {
    margin-right: 14rem;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-14 {
    margin-bottom: 14rem;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-14 {
    margin-left: 14rem;
  }
}

.margin-16 {
  margin: 16rem;
}

.margin-top-16 {
  margin-top: 16rem;
}

.margin-right-16 {
  margin-right: 16rem;
}

.margin-bottom-16 {
  margin-bottom: 16rem;
}

.margin-left-16 {
  margin-left: 16rem;
}

@media (min-width: 30rem) {
  .margin-top-small-16 {
    margin-top: 16rem;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-16 {
    margin-right: 16rem;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-16 {
    margin-bottom: 16rem;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-16 {
    margin-left: 16rem;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-16 {
    margin-top: 16rem;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-16 {
    margin-right: 16rem;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-16 {
    margin-bottom: 16rem;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-16 {
    margin-left: 16rem;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-16 {
    margin-top: 16rem;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-16 {
    margin-right: 16rem;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-16 {
    margin-bottom: 16rem;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-16 {
    margin-left: 16rem;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-16 {
    margin-top: 16rem;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-16 {
    margin-right: 16rem;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-16 {
    margin-bottom: 16rem;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-16 {
    margin-left: 16rem;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-16 {
    margin-top: 16rem;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-16 {
    margin-right: 16rem;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-16 {
    margin-bottom: 16rem;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-16 {
    margin-left: 16rem;
  }
}

.margin-18 {
  margin: 18rem;
}

.margin-top-18 {
  margin-top: 18rem;
}

.margin-right-18 {
  margin-right: 18rem;
}

.margin-bottom-18 {
  margin-bottom: 18rem;
}

.margin-left-18 {
  margin-left: 18rem;
}

@media (min-width: 30rem) {
  .margin-top-small-18 {
    margin-top: 18rem;
  }
}

@media (min-width: 30rem) {
  .margin-right-small-18 {
    margin-right: 18rem;
  }
}

@media (min-width: 30rem) {
  .margin-bottom-small-18 {
    margin-bottom: 18rem;
  }
}

@media (min-width: 30rem) {
  .margin-left-small-18 {
    margin-left: 18rem;
  }
}

@media (min-width: 48rem) {
  .margin-top-medium-18 {
    margin-top: 18rem;
  }
}

@media (min-width: 48rem) {
  .margin-right-medium-18 {
    margin-right: 18rem;
  }
}

@media (min-width: 48rem) {
  .margin-bottom-medium-18 {
    margin-bottom: 18rem;
  }
}

@media (min-width: 48rem) {
  .margin-left-medium-18 {
    margin-left: 18rem;
  }
}

@media (min-width: 64rem) {
  .margin-top-large-18 {
    margin-top: 18rem;
  }
}

@media (min-width: 64rem) {
  .margin-right-large-18 {
    margin-right: 18rem;
  }
}

@media (min-width: 64rem) {
  .margin-bottom-large-18 {
    margin-bottom: 18rem;
  }
}

@media (min-width: 64rem) {
  .margin-left-large-18 {
    margin-left: 18rem;
  }
}

@media (min-width: 80rem) {
  .margin-top-xlarge-18 {
    margin-top: 18rem;
  }
}

@media (min-width: 80rem) {
  .margin-right-xlarge-18 {
    margin-right: 18rem;
  }
}

@media (min-width: 80rem) {
  .margin-bottom-xlarge-18 {
    margin-bottom: 18rem;
  }
}

@media (min-width: 80rem) {
  .margin-left-xlarge-18 {
    margin-left: 18rem;
  }
}

@media (min-width: 100rem) {
  .margin-top-full-18 {
    margin-top: 18rem;
  }
}

@media (min-width: 100rem) {
  .margin-right-full-18 {
    margin-right: 18rem;
  }
}

@media (min-width: 100rem) {
  .margin-bottom-full-18 {
    margin-bottom: 18rem;
  }
}

@media (min-width: 100rem) {
  .margin-left-full-18 {
    margin-left: 18rem;
  }
}

.padding-0 {
  padding: 0;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-left-0 {
  padding-left: 0;
}

@media (min-width: 30rem) {
  .padding-top-small-0 {
    padding-top: 0;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-0 {
    padding-right: 0;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-0 {
    padding-bottom: 0;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-0 {
    padding-left: 0;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-0 {
    padding-top: 0;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-0 {
    padding-right: 0;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-0 {
    padding-bottom: 0;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-0 {
    padding-left: 0;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-0 {
    padding-top: 0;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-0 {
    padding-right: 0;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-0 {
    padding-bottom: 0;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-0 {
    padding-left: 0;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-0 {
    padding-top: 0;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-0 {
    padding-right: 0;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-0 {
    padding-bottom: 0;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-0 {
    padding-left: 0;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-0 {
    padding-top: 0;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-0 {
    padding-right: 0;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-0 {
    padding-bottom: 0;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-0 {
    padding-left: 0;
  }
}

.padding-2 {
  padding: 2rem;
}

.padding-top-2 {
  padding-top: 2rem;
}

.padding-right-2 {
  padding-right: 2rem;
}

.padding-bottom-2 {
  padding-bottom: 2rem;
}

.padding-left-2 {
  padding-left: 2rem;
}

@media (min-width: 30rem) {
  .padding-top-small-2 {
    padding-top: 2rem;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-2 {
    padding-right: 2rem;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-2 {
    padding-bottom: 2rem;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-2 {
    padding-left: 2rem;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-2 {
    padding-top: 2rem;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-2 {
    padding-right: 2rem;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-2 {
    padding-bottom: 2rem;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-2 {
    padding-left: 2rem;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-2 {
    padding-top: 2rem;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-2 {
    padding-right: 2rem;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-2 {
    padding-bottom: 2rem;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-2 {
    padding-left: 2rem;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-2 {
    padding-top: 2rem;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-2 {
    padding-right: 2rem;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-2 {
    padding-bottom: 2rem;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-2 {
    padding-left: 2rem;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-2 {
    padding-top: 2rem;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-2 {
    padding-right: 2rem;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-2 {
    padding-bottom: 2rem;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-2 {
    padding-left: 2rem;
  }
}

.padding-3 {
  padding: 3rem;
}

.padding-top-3 {
  padding-top: 3rem;
}

.padding-right-3 {
  padding-right: 3rem;
}

.padding-bottom-3 {
  padding-bottom: 3rem;
}

.padding-left-3 {
  padding-left: 3rem;
}

@media (min-width: 30rem) {
  .padding-top-small-3 {
    padding-top: 3rem;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-3 {
    padding-right: 3rem;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-3 {
    padding-bottom: 3rem;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-3 {
    padding-left: 3rem;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-3 {
    padding-top: 3rem;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-3 {
    padding-right: 3rem;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-3 {
    padding-bottom: 3rem;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-3 {
    padding-left: 3rem;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-3 {
    padding-top: 3rem;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-3 {
    padding-right: 3rem;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-3 {
    padding-bottom: 3rem;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-3 {
    padding-left: 3rem;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-3 {
    padding-top: 3rem;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-3 {
    padding-right: 3rem;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-3 {
    padding-bottom: 3rem;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-3 {
    padding-left: 3rem;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-3 {
    padding-top: 3rem;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-3 {
    padding-right: 3rem;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-3 {
    padding-bottom: 3rem;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-3 {
    padding-left: 3rem;
  }
}

.padding-4 {
  padding: 4rem;
}

.padding-top-4 {
  padding-top: 4rem;
}

.padding-right-4 {
  padding-right: 4rem;
}

.padding-bottom-4 {
  padding-bottom: 4rem;
}

.padding-left-4 {
  padding-left: 4rem;
}

@media (min-width: 30rem) {
  .padding-top-small-4 {
    padding-top: 4rem;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-4 {
    padding-right: 4rem;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-4 {
    padding-bottom: 4rem;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-4 {
    padding-left: 4rem;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-4 {
    padding-top: 4rem;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-4 {
    padding-right: 4rem;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-4 {
    padding-bottom: 4rem;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-4 {
    padding-left: 4rem;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-4 {
    padding-top: 4rem;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-4 {
    padding-right: 4rem;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-4 {
    padding-bottom: 4rem;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-4 {
    padding-left: 4rem;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-4 {
    padding-top: 4rem;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-4 {
    padding-right: 4rem;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-4 {
    padding-bottom: 4rem;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-4 {
    padding-left: 4rem;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-4 {
    padding-top: 4rem;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-4 {
    padding-right: 4rem;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-4 {
    padding-bottom: 4rem;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-4 {
    padding-left: 4rem;
  }
}

.padding-6 {
  padding: 6rem;
}

.padding-top-6 {
  padding-top: 6rem;
}

.padding-right-6 {
  padding-right: 6rem;
}

.padding-bottom-6 {
  padding-bottom: 6rem;
}

.padding-left-6 {
  padding-left: 6rem;
}

@media (min-width: 30rem) {
  .padding-top-small-6 {
    padding-top: 6rem;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-6 {
    padding-right: 6rem;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-6 {
    padding-bottom: 6rem;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-6 {
    padding-left: 6rem;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-6 {
    padding-top: 6rem;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-6 {
    padding-right: 6rem;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-6 {
    padding-bottom: 6rem;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-6 {
    padding-left: 6rem;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-6 {
    padding-top: 6rem;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-6 {
    padding-right: 6rem;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-6 {
    padding-bottom: 6rem;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-6 {
    padding-left: 6rem;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-6 {
    padding-top: 6rem;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-6 {
    padding-right: 6rem;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-6 {
    padding-bottom: 6rem;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-6 {
    padding-left: 6rem;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-6 {
    padding-top: 6rem;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-6 {
    padding-right: 6rem;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-6 {
    padding-bottom: 6rem;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-6 {
    padding-left: 6rem;
  }
}

.padding-8 {
  padding: 8rem;
}

.padding-top-8 {
  padding-top: 8rem;
}

.padding-right-8 {
  padding-right: 8rem;
}

.padding-bottom-8 {
  padding-bottom: 8rem;
}

.padding-left-8 {
  padding-left: 8rem;
}

@media (min-width: 30rem) {
  .padding-top-small-8 {
    padding-top: 8rem;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-8 {
    padding-right: 8rem;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-8 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-8 {
    padding-left: 8rem;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-8 {
    padding-top: 8rem;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-8 {
    padding-right: 8rem;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-8 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-8 {
    padding-left: 8rem;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-8 {
    padding-top: 8rem;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-8 {
    padding-right: 8rem;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-8 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-8 {
    padding-left: 8rem;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-8 {
    padding-top: 8rem;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-8 {
    padding-right: 8rem;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-8 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-8 {
    padding-left: 8rem;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-8 {
    padding-top: 8rem;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-8 {
    padding-right: 8rem;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-8 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-8 {
    padding-left: 8rem;
  }
}

.padding-10 {
  padding: 8rem;
}

.padding-top-10 {
  padding-top: 8rem;
}

.padding-right-10 {
  padding-right: 8rem;
}

.padding-bottom-10 {
  padding-bottom: 8rem;
}

.padding-left-10 {
  padding-left: 8rem;
}

@media (min-width: 30rem) {
  .padding-top-small-10 {
    padding-top: 8rem;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-10 {
    padding-right: 8rem;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-10 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-10 {
    padding-left: 8rem;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-10 {
    padding-top: 8rem;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-10 {
    padding-right: 8rem;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-10 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-10 {
    padding-left: 8rem;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-10 {
    padding-top: 8rem;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-10 {
    padding-right: 8rem;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-10 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-10 {
    padding-left: 8rem;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-10 {
    padding-top: 8rem;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-10 {
    padding-right: 8rem;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-10 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-10 {
    padding-left: 8rem;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-10 {
    padding-top: 8rem;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-10 {
    padding-right: 8rem;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-10 {
    padding-bottom: 8rem;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-10 {
    padding-left: 8rem;
  }
}

.padding-12 {
  padding: 12rem;
}

.padding-top-12 {
  padding-top: 12rem;
}

.padding-right-12 {
  padding-right: 12rem;
}

.padding-bottom-12 {
  padding-bottom: 12rem;
}

.padding-left-12 {
  padding-left: 12rem;
}

@media (min-width: 30rem) {
  .padding-top-small-12 {
    padding-top: 12rem;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-12 {
    padding-right: 12rem;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-12 {
    padding-bottom: 12rem;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-12 {
    padding-left: 12rem;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-12 {
    padding-top: 12rem;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-12 {
    padding-right: 12rem;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-12 {
    padding-bottom: 12rem;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-12 {
    padding-left: 12rem;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-12 {
    padding-top: 12rem;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-12 {
    padding-right: 12rem;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-12 {
    padding-bottom: 12rem;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-12 {
    padding-left: 12rem;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-12 {
    padding-top: 12rem;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-12 {
    padding-right: 12rem;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-12 {
    padding-bottom: 12rem;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-12 {
    padding-left: 12rem;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-12 {
    padding-top: 12rem;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-12 {
    padding-right: 12rem;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-12 {
    padding-bottom: 12rem;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-12 {
    padding-left: 12rem;
  }
}

.padding-14 {
  padding: 14rem;
}

.padding-top-14 {
  padding-top: 14rem;
}

.padding-right-14 {
  padding-right: 14rem;
}

.padding-bottom-14 {
  padding-bottom: 14rem;
}

.padding-left-14 {
  padding-left: 14rem;
}

@media (min-width: 30rem) {
  .padding-top-small-14 {
    padding-top: 14rem;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-14 {
    padding-right: 14rem;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-14 {
    padding-bottom: 14rem;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-14 {
    padding-left: 14rem;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-14 {
    padding-top: 14rem;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-14 {
    padding-right: 14rem;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-14 {
    padding-bottom: 14rem;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-14 {
    padding-left: 14rem;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-14 {
    padding-top: 14rem;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-14 {
    padding-right: 14rem;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-14 {
    padding-bottom: 14rem;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-14 {
    padding-left: 14rem;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-14 {
    padding-top: 14rem;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-14 {
    padding-right: 14rem;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-14 {
    padding-bottom: 14rem;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-14 {
    padding-left: 14rem;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-14 {
    padding-top: 14rem;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-14 {
    padding-right: 14rem;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-14 {
    padding-bottom: 14rem;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-14 {
    padding-left: 14rem;
  }
}

.padding-16 {
  padding: 16rem;
}

.padding-top-16 {
  padding-top: 16rem;
}

.padding-right-16 {
  padding-right: 16rem;
}

.padding-bottom-16 {
  padding-bottom: 16rem;
}

.padding-left-16 {
  padding-left: 16rem;
}

@media (min-width: 30rem) {
  .padding-top-small-16 {
    padding-top: 16rem;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-16 {
    padding-right: 16rem;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-16 {
    padding-bottom: 16rem;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-16 {
    padding-left: 16rem;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-16 {
    padding-top: 16rem;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-16 {
    padding-right: 16rem;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-16 {
    padding-bottom: 16rem;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-16 {
    padding-left: 16rem;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-16 {
    padding-top: 16rem;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-16 {
    padding-right: 16rem;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-16 {
    padding-bottom: 16rem;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-16 {
    padding-left: 16rem;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-16 {
    padding-top: 16rem;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-16 {
    padding-right: 16rem;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-16 {
    padding-bottom: 16rem;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-16 {
    padding-left: 16rem;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-16 {
    padding-top: 16rem;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-16 {
    padding-right: 16rem;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-16 {
    padding-bottom: 16rem;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-16 {
    padding-left: 16rem;
  }
}

.padding-18 {
  padding: 18rem;
}

.padding-top-18 {
  padding-top: 18rem;
}

.padding-right-18 {
  padding-right: 18rem;
}

.padding-bottom-18 {
  padding-bottom: 18rem;
}

.padding-left-18 {
  padding-left: 18rem;
}

@media (min-width: 30rem) {
  .padding-top-small-18 {
    padding-top: 18rem;
  }
}

@media (min-width: 30rem) {
  .padding-right-small-18 {
    padding-right: 18rem;
  }
}

@media (min-width: 30rem) {
  .padding-bottom-small-18 {
    padding-bottom: 18rem;
  }
}

@media (min-width: 30rem) {
  .padding-left-small-18 {
    padding-left: 18rem;
  }
}

@media (min-width: 48rem) {
  .padding-top-medium-18 {
    padding-top: 18rem;
  }
}

@media (min-width: 48rem) {
  .padding-right-medium-18 {
    padding-right: 18rem;
  }
}

@media (min-width: 48rem) {
  .padding-bottom-medium-18 {
    padding-bottom: 18rem;
  }
}

@media (min-width: 48rem) {
  .padding-left-medium-18 {
    padding-left: 18rem;
  }
}

@media (min-width: 64rem) {
  .padding-top-large-18 {
    padding-top: 18rem;
  }
}

@media (min-width: 64rem) {
  .padding-right-large-18 {
    padding-right: 18rem;
  }
}

@media (min-width: 64rem) {
  .padding-bottom-large-18 {
    padding-bottom: 18rem;
  }
}

@media (min-width: 64rem) {
  .padding-left-large-18 {
    padding-left: 18rem;
  }
}

@media (min-width: 80rem) {
  .padding-top-xlarge-18 {
    padding-top: 18rem;
  }
}

@media (min-width: 80rem) {
  .padding-right-xlarge-18 {
    padding-right: 18rem;
  }
}

@media (min-width: 80rem) {
  .padding-bottom-xlarge-18 {
    padding-bottom: 18rem;
  }
}

@media (min-width: 80rem) {
  .padding-left-xlarge-18 {
    padding-left: 18rem;
  }
}

@media (min-width: 100rem) {
  .padding-top-full-18 {
    padding-top: 18rem;
  }
}

@media (min-width: 100rem) {
  .padding-right-full-18 {
    padding-right: 18rem;
  }
}

@media (min-width: 100rem) {
  .padding-bottom-full-18 {
    padding-bottom: 18rem;
  }
}

@media (min-width: 100rem) {
  .padding-left-full-18 {
    padding-left: 18rem;
  }
}